@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import './_mixins.scss';

html,body {
    font-family: 'Lato', sans-serif;
}
 

html {
height: 100%;
 }

body {
    min-height: 100%;
}

.application {
    width:100%;
    min-height:100vh;
    position: relative;
}
  
h2 {
    font-size: 25px;
}

a {
    cursor:pointer;
    text-decoration:none !important;

    &:hover {
        text-decoration: none;
    }
}


.btn {
    min-width: 150px;
}

.box--rounded {
    border-radius: $base-box-radius;
}
