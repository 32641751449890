$base-grey:#282828;
$base-grey-hover:#505050;

$base-blue:#0090d6;
$base-blue-hover:#03a0ee;

$base-box-radius:10px;


$buttonTypes: (
    'large': ('width':150px,'line-height':50px),
    'small': ('width':100px,'line-height':30px),
);


@mixin button($type, $background ,$backgroundHover) { 

    $type:  map-get($buttonTypes, $type);

    @if $type {
        width: map-get($type, 'width');        
        line-height:  map-get($type, 'line-height');   
    }   
   
    border:none;
    background-color:$background ;
    border-radius:5px;
    color:#fff;
    -webkit-transition: background-color 250ms ease-out;
    -moz-transition: background-color 250ms ease-out;
    -o-transition: background-color 250ms ease-out;
    transition: background-color 250ms ease-out;
    
    text-align: center;

    &:hover {
        background-color: $backgroundHover;
    }
  }
