@import "./_common.scss"; // assuming a styles directory under src/
@import "~bootstrap/scss/bootstrap";

.app-header {
  background: $base-grey;
  height: 80px;
  padding: 0px 20px;
}

.container--app {
  padding: 30px 0px 100px 0px;
  font-size: 14px;
}

.navbar-brand { margin-right: 60px;}

.navbar-link--logout {
  color: #fff;

  &:hover {color:#fff; }
}

.navbar-link--page {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  margin: 0px 20px;
  text-decoration: none;

  &.active { border-bottom:1px solid $base-blue; }

  &:hover {color:$base-blue;}

}

.navbar-options--user-name {
  color: #fff;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #fff;
}

.app-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #4a4a4a;
  font-size: 13px;
  line-height: 30px;
  padding-right: 10px;
}

/**

LOGIN PAGE

 **/


.container--login {
  width: 100%;
  height:100vh;
  background:$base-grey;
  text-align: center;
  background: url("./images/login/login-page--bg.png") no-repeat top center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-panel--container{
  width: 500px;
  margin: auto;
  text-align: left;
}

.login-panel--brand {
  margin-bottom: 20px;
  text-align: center;
}

.login-panel {
  padding: 30px;
  background: #fff;
  border-radius: $base-box-radius;
  box-shadow: 0px 0px 20px #000;
}

.login-panel--form .form-group {
  margin-top: 10px;
}

.login-panel--form .form-group select {
  cursor: pointer;
}


.login-panel--header {
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.login-panel--actions { 
  text-align: center;
  margin-top: 30px;
}

.login-copy {
  text-align: center;
  font-size: 12px;
  color: #6f6f6f;
  padding-top: 20px;
}

.message-alert--login { margin-top: 20px;}

.login-panel select {
  -moz-appearance: auto !important; 
  -webkit-appearance: auto !important;
  appearance: auto !important;
}

/**

DASHBOARD VIEW 

**/

.dashboard-active-env--data {
  display: flex;
  margin-top: 50px;
}

.dashboard-env--info {
  padding-left: 50px;
  border-left: 1px solid #b3b3b3;
}

.dashboard-env--info h2 {
  font-size: 25px;
}

.dashboard-env--info a {
  display: inline-block;
  margin-top: 30px;

  @include button('large', $base-blue, $base-blue-hover);
}

.dashboard-env--image {
  padding-right: 50px;
}

.dashboard-list-env--container {
  margin-top: 50px;
}

.dashboard-list-env--list-item {
  padding: 20px 0px;
  border-bottom: 1px solid #cecece;
  display: flex;
}

.dashboard-list-env--list-item > div {
  flex: 1;
}

.dashboard-list-env--list-button {
  text-align: right;
}

.dashboard-list-env--list-button button {
  @include button('small', $base-grey, $base-grey-hover);
}

.dashboard-list-env--change {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;

  & a {
    color: $base-blue;
    
    &:hover {
      $base-blue-hover:#03a0ee;
    }
  }
}


/**

 SEARCH SECTION

 **/

.section-search {
  border-bottom: 1px solid #cecece;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.section-header {
  color:$base-blue;
  margin-bottom: 20px;
}

.env_details  {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.env_details span {
  padding-right: 20px;
}

.option--find--type {
  flex-grow: 0;

  & select { width: 200px;}
}


.search-form--advance-header {
  margin: 20px 0px;
  font-weight: bold;

  & button {
      color:$base-blue;
      margin-left: 5px;
      border: 0px; 
      text-align: center;
      background: none;
  } 
}

.search-form--advance-container {
  background: #ececec;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin: 20px 0px;
}

.search-form--element-full-width {
  flex-basis: 100%;
}

.search-form--options > .col {
  text-align: right;
  padding: 20px 10px 0px 10px
}

/**

DATA LOADER 

**/

.lookin-for--container,
.no-results--container {
    padding-top: 100px;
    text-align: center;
    margin-bottom: 100px;

    img {
      margin-bottom: 30px;
    }
}

.output-loader--container {
  text-align: center;
  border: 1px solid #cecece;
  border-radius: $base-box-radius;
  padding: 50px 20px;
}

.output-loader--header {
  font-size: 20px;
  color: $base-blue;
  text-transform: uppercase;
}

.output-loader--dots::after {
  display: inline-block;
  height: 10px;
  width: 10px;
  content:'.'; 
  height: 10px;
  animation: dot 1s infinite linear;
}

@keyframes dot {
  0%    { content:'.'; }
  25%   { content:'..';  }
  50%   { content:'...'; }
  75%   { content:'....'; }
  100%  { content:''; }
}

/** 

BREAD CRUMBS 

**/

.output-breadcrumb--container {
  margin-bottom: 20px;
  font-size: 14px;

  a { color:$base-blue !important; }
}


/** 

LIST OPTIONS 

**/

.output-list-options--container {
  display: flex;
  margin-bottom: 20px;

  button {
      padding: 0px 0px 0px 10px;
      color: #000;
      border:none;
      cursor: pointer;
      background: none;

      &:after {
        content:"|";
        display: inline-block;
        padding-left: 10px;
      }

      &:last-child:after {
        display: none;
      }

      &.option-active {
          color:$base-blue;
          font-weight: bold;
      }

      &.option-active.sort-asc:after,
      &.option-active.sort-desc:after {
          display: inline-block;
          content:'';
          width: 10px;
          height: 10px;
          margin-left: 5px;
      }

      &.option-active.sort-asc:after {  background: url("./images/game-history/arrow-asc.png") no-repeat center center;  }
      &.option-active.sort-desc:after {  background: url("./images/game-history/arrow-desc.png") no-repeat center center;  }      
  }
  
}

.output-list--export { flex-grow: 2; }

.option-save {
  display: flex;

  &:before {
      display: inline-block;
      float: left;
      content:'';
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background: url("./images/game-history/save-icon.png") no-repeat center center;
  }
}

.output-list--perPage { margin-left: 50px;}


/** 

OUTPUT TABLE 

**/

.output-list-data--container {
  padding: 15px;
  border-radius: $base-box-radius;
  border: 1px solid #cecece;
}

.output-table {
  font-size: 12px;
  
  th { height: 40px; }
  tr {
    border-bottom: 1px solid #cecece; 

    &:last-child { border-bottom:0px; }
    &:hover { background: #e9e9e9; }
  }

  td,th { padding: 5px 10px; }
}

.output-table--header {
  background: #dcdcdc;
  border-bottom: 1px solid #b9b9b9;
}

.output-table--action-col {
  text-align: right;
  padding-right: 10px !important;

  a {
      color:$base-blue !important;
      font-weight: bold;
  }
}

.output-table--date-col {}



/** 

OUTPUT SESSION GROUP  - SCALETECH

**/

.output-session-group--header {
  font-size: 14px;
  font-weight: bold;
  padding: 25px 0px 10px 0px;
}

.output-session-group:first-child .output-session-group--header {  padding-top: 0px;} 

.output-session-group--cycles {}

.output-session-group--cycles-header,
.output-session-group--cycles-item {
    display: flex;
    flex-grow: 1;
}

.output-session-group--cycles-header > div,
.output-session-group--cycles-item > div {
    flex-grow: 1;
    flex-basis: 0;
}


.output-session-group--cycles-header {
  padding: 5px 10px;
  background: #dcdcdc;
  font-weight: 700;
  line-height: 20px;
  border-radius: 5px 5px 0px 0px;
}

.output-session-group--cycles-item {
   border-bottom: 1px solid #cecece;
   padding: 5px 10px;

   &:hover {
    background: #e9e9e9;
   }

   &:last-child {
    border: none;
  } 
}

.output-session-group--cycles-details {
  text-align: right;

  & a {
    color: #0090d6;
    font-weight: bold;
  }
}

.output-session-group {
   font-size:12px;
}

.output-table--session-id-col {
  position: relative;
}

.output-table--freespin-badge {
    position: absolute;
    top: 5px;
    right: 0px;
    background: #cce1ff;
    border-left: 1px solid #0d6efd;
    color: #0090d6;
    font-weight: bold;
    line-height: 13px;
    padding: 5px 10px;
    border-radius: 3px;
}

span.output-table--freespin-label {
  display: block;
  font-size: 11px;
  line-height: 20px;
  color: #0090d9;
  font-weight: bold;
}

/** 

OUTPUT CYCLES HISTORY - SCALETECH

**/

.output-cycle-history {}

.output-cycle-history--item {
  border-bottom: 1px solid  #cecece;
  padding-bottom: 5px;

  &:last-child {
    border: none;
  }

  &:hover {
    background: #e9e9e9;
  }
}

.output-cycle-history--item-header, 
.output-cycle-history--item-data {
  display: flex;
}

.output-cycle-history--item-header > div, 
.output-cycle-history--item-data > div {
  flex-grow: 1;
  flex-basis: 0;
}
.output-cycle-history--item-gameCycleId {
  flex-grow: 0 !important;
  flex-basis: 280px !important;
}

.output-cycle-history-details {
  font-size: 12px;
}

.output-cycle-history--item-header {
  padding: 5px 15px;
  background: #dcdcdc;
  font-weight: bold;
  line-height: 20px;
  border-radius: 5px 5px 0px 0px;
}

.output-cycle-history--item-data{
  padding: 5px 10px;
}

.output-cycle-history--item-details {
  text-align: right;

  & a {
    color:#0090d6;
    font-weight: bold;
  }
}

.output-cycle-history--item-actions {
  padding-left: 10px;
  width: 100%;
}

.output-cycle-history--item-actions.block-show-false {display: none;}
.output-cycle-history--item-actions.block-show-true {display: inline-block}

.output-cycle-history--action {
  display: flex;
  width: 50%;
  padding: 5px 15px;
}

.output-cycle-history--action span {
  flex-grow: 1;
  flex-basis: 1%
}

.output-cycle-history--action span:last-child {
  flex-basis: 100px
}

.output-cycle-history--item-actions.item-actions--header {
  font-weight: 700;
  padding-bottom: 5px;

  & span {
    cursor: pointer;
  }
}

/** 

PAGINATION 

**/

.output-list-pagination--container {
  margin-top: 20px;
  text-align: center;
}

.output-list-pagination--links {
  margin-top: 10px;
}

.output-list-pagination--links > button {
  display: inline-block;
  background: #e7e7e7;
  margin: 5px;
  width: 32px;
  line-height: 30px;
  border-radius: 20px;
  font-weight: 700;
  color: #7e7e7e;
  border: 0px;
}

button.output-list-pagination-link:hover {
  background: #c6c6c6;
  color: #fff;
}

.output-list-pagination--links > button.link-active {
   background: #0d6efd;
   color: #ffffff;
   cursor: default;
}
/** 

CYCLE DETAILS 

**/

.output-cycle-details {

  h1 {
      font-size: 20px;
      margin-bottom: 30px;
      border-bottom: 1px dotted #cecece;
      padding-bottom: 20px;
  }

  h2 { 
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.cycle-details--item { display: flex; }

.cycle-details--label  {min-width: 140px; }

.output-cycle-actions--section.row {
  margin-top: 50px;
}

.output-cycle-actions--item {
  display: flex;
  border-bottom: 1px solid #cecece;

  > div {
      flex-basis: auto;
      padding: 20px;
  }

  &:last-child {
    border: none;
  }
}

.cycle-details--item-desc { padding:10px 0px 20px 10px }

.cycle-details--item-desc span {
  padding-left: 10px;
  color: red;
  font-style: italic
}

.cycle-details--free-spin {
  margin-top: -20px;
  padding: 20px;
  background: #e6f0ff;
  border: 1px solid #0d6efd;
  border-radius: 10px;
}

/** CYCLE ACTIONS **/

.cycle-col-index { width: 40px; }
.cycle-col-text { width: 380px; }
.cycle-col-symbols { width: 650px; }
.cycle-symbols {}

.cycle-symbols-grid--container {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.cycle-symbols-grid {
  transform: scale(0.8);
  margin: 0 auto calc(-450px * (1 - 0.8));
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  transform-origin: 0% 0;
  width: 750px;
}

.row_size_3 { height: 457px; }
.row_size_4 { height: 606px; }

.cycle-symbols-winline {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 10;
}

.cycle-symbols-reels {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;

  &:before  {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 50;
      border: 3px solid #000000;
      border-radius: 10px;
  }
}
.cycle-symbols-grid--col {
 
  div {
      text-align: center;
      line-height: 30px;
  }

}

.cycle-symbols-grid-row {
  padding: 1px;
  box-sizing: border-box;
}

.cycle-symbols-grid-image {
  background: #cecece;
  border-radius: 2px;
  border-radius: 10px;
  overflow: hidden;
}

.cycle-winlines-details {

  span {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      margin: 5px;
      border: 1px solid #cecece;
      line-height: 30px;
      border-radius: 2px;
      cursor: pointer;
      font-weight: bold;
  }

  span:hover {
      background: $base-blue;
      color: #fff;
  }
}

